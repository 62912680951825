<template>
  <div id="app">
    <company-logo />
  </div>
</template>

<script>
import CompanyLogo from './components/company-logo.vue'

export default {
  name: 'App',

  components: {
    CompanyLogo
  }
}
</script>

<style lang="scss">
html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

#app {
  background-color: #191919;
  height: 100vh;
  box-sizing: border-box;
}
</style>